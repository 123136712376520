import React, { useState, useEffect } from 'react'
import { List, Cell, Section, Badge, Button} from '@telegram-apps/telegram-ui'
import { 
  Instagram, 
  Twitter, 
  SendHorizontal, 
  Check, 
  Link as LinkIcon, 
  Activity 
} from 'lucide-react'
import { authtoken } from '../App.js';

function TasksSection() {
  const [rewards, setRewards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonStates, setButtonStates] = useState({});

  const platformIcons = {
    'Join Telegram Channel': SendHorizontal,
    'Follow  X (Twitter)': Twitter,
    'Follow  Instagram': Instagram,
    'default': Activity
  };

  useEffect(() => {
    const fetchRewards = async () => {
      try {
        const response = await fetch('https://lets-connect.org/api/user/external-reward', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'Cache-Control': 'no-cache',
            'Connection': 'keep-alive',
            'Authorization': `Bearer ${authtoken}`
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const normalizedRewards = data.result.map(reward => ({
          ...reward,
          name: (reward.name || 'Loading...')
        }));

        setRewards(normalizedRewards);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching rewards:', error);
        setLoading(false);
      }
    };

    fetchRewards();
  }, []);


 // Ensure buttonStates has default values for rewards
 useEffect(() => {
  setButtonStates(rewards.reduce((acc, reward) => {
    acc[reward.name] = reward.isAppliedByUser
      ? { loading: false, text: 'Done', disabled: true }
      : { loading: false, text: 'Go', disabled: false };
    return acc;
  }, {}));
}, [rewards]);


const handleTaskAction = async (reward) => {
  setButtonStates(prev => ({
    ...prev,
    [reward.name]: { loading: true, disabled: true }
  }));

  if (reward.link) {
    window.open(reward.link, '_blank', 'noopener,noreferrer');
  }

  try {
    await new Promise(resolve => setTimeout(resolve, 2500));

    setButtonStates(prev => ({
      ...prev,
      [reward.name]: { loading: false, text: 'Claim', disabled: false }
    }));
  } catch (error) {
    console.error('Error during initial task action:', error);
    setButtonStates(prev => ({
      ...prev,
      [reward.name]: { loading: false, text: 'Go', disabled: false }
    }));
  }
};

const handleClaim = async (reward) => {
  setButtonStates(prev => ({
    ...prev,
    [reward.name]: { loading: true, disabled: true }
  }));

  try {
    await new Promise(resolve => setTimeout(resolve, 500));
    const response = await fetch('https://lets-connect.org/api/user/external-reward', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': '*/*',
        'Cache-Control': 'no-cache',
        'Connection': 'keep-alive',
        'Authorization': `Bearer ${authtoken}`
      },
      body: JSON.stringify({
        name: reward.name
      })
    });

    if (!response.ok) {
      // Log the server response when not OK
      const errorData = await response.json();
      console.error('Server error response:', errorData);
      throw new Error('Failed to claim reward');
    }

    // Mark reward as applied
    setRewards(prev =>
      prev.map(r => r.name === reward.name ? { ...r, isAppliedByUser: true } : r)
    );

    // Update button to "Done" if reward is applied
    setButtonStates(prev => ({
      ...prev,
      [reward.name]: { loading: false, text: 'Done', disabled: true }
    }));

  } catch (error) {
    console.error('Error claiming reward:', error);

    // Log the server response if an error occurs
    if (error.response) {
      const errorResponse = await error.response.json();
      console.error('Error response from server:', errorResponse);
    }

    setButtonStates(prev => ({
      ...prev,
      [reward.name]: { loading: false, text: 'Claim', disabled: false }
    }));
  }
};

  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <List 
      style={{
        background: 'var(--tgui--secondary_bg_color)',
        width: '100%',
      }}
    >
      <Section header="Tasks">
        {rewards.map((reward) => {
          const Icon = platformIcons[reward.name] || platformIcons['default'];
          const buttonState = buttonStates[reward.name] || {};
          const isLinkAvailable = !!reward.link;
          
          const buttonText = buttonState.text || 
            (reward.isAppliedByUser 
              ? 'Done' 
              : (isLinkAvailable ? 'Go' : 'Unavailable'));
          const isDisabled = buttonState.disabled || 
            reward.isAppliedByUser || 
            !isLinkAvailable;

          return (
            <Cell
              key={reward.name}
              interactiveAnimation="opacity"
              after={
                <Button
                onClick={() => {
                  if (buttonStates[reward.name]?.text === 'Claim') {
                    handleClaim(reward);
                  } else if (buttonStates[reward.name]?.text === 'Go') {
                    handleTaskAction(reward);
                  }
                }}
                disabled={buttonStates[reward.name]?.disabled || false}
                loading = {buttonStates[reward.name]?.loading}
              >
                {buttonStates[reward.name]?.text || 'Go'}
              </Button>

              
              }
              before={<Icon />}
              subtitle={`+${reward.reward} $EUREKA`}
              titleBadge={<Badge type="dot" />}
            >
              {reward.name}
            </Cell>
          );
        })}
      </Section>
    </List>
  );
}

export default TasksSection;